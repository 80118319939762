let count = 0;

const headerEl = document.querySelector('.nobel-leuk-man');
const nobelIsLeukEl = document.querySelector('.nobel-is-leuk');
const buttonEl = document.querySelector('h2 button');
const sectionEl = document.querySelector('.image-section');

window.onscroll = () => {
    const scroll = window.scrollY;
    headerEl.style.top = `${scroll / 2}px`;

    const top = sectionEl.offsetTop;
    const percentage = (top - scroll) / window.innerHeight;
    const val = 75 - 50 * percentage;
    sectionEl.style.backgroundPositionY = `${val.toFixed(2)}%`;
}

function generateLetters() {
    console.log(nobelIsLeukEl);
}

generateLetters();
